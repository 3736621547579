import React, { useEffect, useState } from 'react'
import { apiHandler, apiImageHandler } from '../api/APIHandler';
import Card from '../component/ArtListSectionComponent';
import { useMediaQueries } from '../hook';

function ArtSculpture() {

    const [cardsData, setCardsData] = useState([]);
    const { md, lg } = useMediaQueries()
    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await apiHandler('items/sculpture_lists?offset=0&sort=-id');
                const cardDataWithImages = await Promise.all(data.map(async (card) => {
                    const thumbnail_1 = await apiImageHandler(card.thumbnail);
                    const thumbnail_2 = await apiImageHandler(card.thumbnail_2)
                    const thumbnail_3 = await apiImageHandler(card.thumbnail_3)
                    const thumbnail_4 = await apiImageHandler(card.thumbnail_4)
                    const thumbnail_5 = await apiImageHandler(card.thumbnail_5)
    
                    const thumbnails = [thumbnail_1, thumbnail_2, thumbnail_3, thumbnail_4, thumbnail_5];

                    // Kembalikan card dengan thumbnails array
                    return { ...card, thumbnails };
                }));
                setCardsData(cardDataWithImages);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);

  if(lg){
    return (
        <div className='min-h-[100vh] bg-[black] pb-[200px] text-white px-[10vw] pt-[200px]'>
            {/* <div className='font-semibold text-[24px] mb-10'>Art list</div> */}
    
            <div className='w-full h-full flex justify-center'>
                <div className='grid grid-cols-3 w-full gap-4'>
                    {cardsData.map((card, index) => (
                        <Card
                            key={index} 
                            thumbnails={card.thumbnails} 
                            title={card.title} 
                            content={card.description}
                            id={card.id}
                            type="sculpture"
                        />
                    ))}
                </div>
            </div>
        </div>
      )
  }

  if(md){
    return (
        <div className=' bg-[black] text-white px-4 pt-6 pb-6 '>
        {/* <div className='font-semibold text-2xl mb-4'>Art list</div> */}
        <div className='w-full h-full flex justify-center'>
            <div className='flex flex-col gap-4 justify-center items-center flex-wrap '>
                    {cardsData.map((card, index) => (
                        <Card
                            key={index} 
                            thumbnails={card.thumbnails}
                            title={card.title} 
                            content={card.description}
                            id={card.id}
                            type="sculpture"
                        />
                    ))}
                </div>
            </div>
        </div>
      )
  }
}

export default ArtSculpture