import React, { useEffect, useState } from 'react'
import TypeDashboardSection from './type-detail/TypeDashboardSection'
import KeepTouchSection from './main-section/KeepTouchSection'
import MapSection from './main-section/MapSection'
import Footer from './component/Footer'
import NextArtSection from './type-detail/NextArtSection'
import Animation from '../assets/animation.JPG'
import { Routes, Route, useParams, Navigate } from 'react-router-dom';
import '../index.css';
import ArtFacade from './type-detail/ArtFacade'
import ArtBuilding from './type-detail/ArtBuilding'
import ArtProduct from './type-detail/ArtProduct'
import ArtSculpture from './type-detail/ArtSculpture'


function TypeDetailPage() {
    const [isVisible, setIsVisible] = useState(false);
    const { type } = useParams();

    useEffect(() => {
        const timer = setTimeout(() => {
            setIsVisible(true);
        }, 4500); // 5000 milliseconds = 5 seconds

        return () => clearTimeout(timer); // Clean up the timer on component unmount
    }, []);

    const getComponentForType = (type) => {
        switch (type) {
            case 'building':
                return <ArtBuilding />;
            case 'facade':
                return <ArtFacade />;
            case 'product':
                return <ArtProduct />;
            case 'sculpture':
                return <ArtSculpture />;
            default:
                return <Navigate to="/404" />; // Redirect to a 404 page or a default route
        }
    };


    return (
        <div className='overflow-x-hidden overflow-y-hidden relative'>
            <div className='h-[100vh] w-[100vw] bg-black overflow-hidden' style={isVisible ? { display: 'none' } : {}}>
                <img src={Animation} className='custom-animation absolute object-cover object-center top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-10' alt='photo' />
            </div>

            <div className={isVisible ? '' : 'hidden'}>
                <div>
                    <TypeDashboardSection />
                </div>

                <Routes>
                    <Route path="*" element={getComponentForType(type)} />
                </Routes>

                <div>
                    <NextArtSection />
                </div>

                <div>
                    <hr />
                </div>

                <div>
                    <KeepTouchSection />
                </div>

                <div>
                    <hr />
                </div>

                {/* <div>
                        <MapSection/>
                    </div> */}

                <div>
                    <Footer />
                </div>
            </div>
        </div>
    )
}

export default TypeDetailPage