import React, { useState, useEffect } from 'react';
import { Offcanvas, Navbar, Nav, NavDropdown } from 'react-bootstrap';
import Logo from '../../assets/logo.png';
import { useMediaQueries } from '../hook';
import '../css/navbar.css'

function NavigationBar() {
  const { md, lg } = useMediaQueries()
  const [show, setShow] = useState(false);
  const [showSide, setShowSide] = useState(false);
  const [isExitSide, setExistSide] = useState(false);
  const [isExiting, setIsExiting] = useState(false);

  const handleToggle = () => {
    if (show) {
      setIsExiting(true);
      setTimeout(() => {
        setShow(false);
        setIsExiting(false);
      }, 450); // Waktu harus sesuai dengan durasi animasi keluar
    } else {
      setShow(true);
    }
  };

  const handleToggleSide = () => {
    if (showSide) {
      setExistSide(true);
      setTimeout(() => {
        setShowSide(false);
        setExistSide(false);
      }, 450); // Waktu harus sesuai dengan durasi animasi keluar
    } else {
      setShowSide(true);
    }
  };
  const [navbarmd, setNavbarmdStyle] = useState({
    height: '10vh', paddingLeft: '1rem', paddingRight: '1rem'
  })
  const [navbarStyle, setNavbarStyle] = useState({
    backgroundColor: 'transparent',
    paddingLeft: '50px',
    paddingRight: '50px',
    paddingTop: '50px',
    paddingBottom: '50px',
    transition: 'background-color 0.3s, padding 0.3s'

  });

  const handleScroll = () => {
    if (window.scrollY > 0) {
      setNavbarStyle({
        backgroundColor: 'rgba(0, 0, 0, 0.7)',
        paddingLeft: '50px',
        paddingRight: '50px',
        paddingTop: '20px',
        paddingBottom: '20px',
        transition: 'background-color 0.3s, padding 0.3s'
      });

      setNavbarmdStyle({
        backgroundColor: 'rgba(0, 0, 0, 0.7)',
        transition: 'background-color 0.3s, padding 0.3s',
        height: '10vh', paddingLeft: '1rem', paddingRight: '1rem'
      })
    } else {
      setNavbarStyle({
        backgroundColor: 'transparent',
        paddingLeft: '50px',
        paddingRight: '50px',
        paddingTop: '50px',
        paddingBottom: '50px',
        transition: 'background-color 0.3s, padding 0.3s'
      });

      setNavbarmdStyle({
        backgroundColor: 'transparent',
        transition: 'background-color 0.3s, padding 0.3s',
        height: '10vh', paddingLeft: '1rem', paddingRight: '1rem'
      })
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  if (lg) {
    return (
      <Navbar expand="sm" data-bs-theme="dark" className="text-[white]" style={navbarStyle}>
        <div className='flex justify-between w-full mx-[5vw] '>
          <Navbar.Brand href="/dashboard" className='font-bold transition duration-[1000ms] hover:scale-[2]' style={{ fontSize: '28px' }}>
            <img width={35} src={Logo} alt="Logo" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav" className="justify-content-between">
            <Nav className="ms-auto text-[white]">
              <Nav.Link style={{ paddingRight: '100px', color: 'white', fontSize: '18px' }} href="/">Bio</Nav.Link>
              <NavDropdown title="Artworks" className='customDropdown' style={{ paddingRight: '3em', fontSize: '18px' }} id="basic-nav-dropdown">
                <NavDropdown.Item href="/art/sculpture" style={{ borderBottom: '1px solid white' }}>Art Sculpture</NavDropdown.Item>
                <NavDropdown.Item href="/art/product" style={{ borderBottom: '1px solid white' }}>Art Product</NavDropdown.Item>
                <NavDropdown.Item href="/art/facade" style={{ borderBottom: '1px solid white' }}>Art Facade</NavDropdown.Item>
                <NavDropdown.Item href="/art/building" style={{ borderBottom: '1px solid white' }}>Art Building</NavDropdown.Item>
              </NavDropdown>
              <Nav.Link style={{ paddingRight: '50px', color: 'white', fontSize: '18px' }} href="/more-news">News</Nav.Link>
              <Nav.Link style={{ paddingRight: '50px', color: 'white', fontSize: '18px' }} href="/contact-us">Contact</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </div>
      </Navbar>
    );
  }

  if (md) {
    return (
      <>
        {showSide ?
          <div className='background-half'>
            <div className={`${showSide ? 'slide-in-blurred-top' : ''} ${isExitSide ? 'slide-out-blurred-top' : ''} bg-black w-[70vw] h-[100vh]`} >
              <div className='flex flex-col bg-black w-[100%] h-[100vh] mx-8 py-[10vh] text-[5vw] gap-10 text-white'>
                <div className='flex justify-end mr-10'>
                  <div className='text-[3vw] relative right-0' onClick={handleToggleSide}>X</div>
                </div>
                <div className='flex justify-between items-center'>
                  <a href='/dashboard'><div className='text-white'>Bio</div></a>
                </div>
                <div className=''>
                  <div onClick={handleToggle} className='cursor-pointer mb-2 bg-black'>
                    Art Works
                  </div>
                  <div className={`${show ? 'slide-in-blurred-top' : ''} ${isExiting ? 'slide-out-blurred-top' : ''} overflow-hidden`}>
                    {show && (
                      <div className='flex flex-col gap-4 mt-2'>
                        <a href="/art/sculpture">
                          <div className='text-[4vw] border-b border-white w-[55%] text-white pb-2'>Art Sculpture</div>
                        </a>
                        <a href="/art/product">
                          <div className='text-[4vw] border-b border-white w-[55%] text-white pb-2'>Art Product</div>
                        </a>
                        <a href="/art/facade">
                          <div className='text-[4vw] border-b border-white w-[55%] text-white pb-2'>Art Facade</div>
                        </a>
                        <a href="/art/building">
                          <div className='text-[4vw] border-b border-white w-[55%] text-white pb-2'>Art Building</div>
                        </a>
                      </div>
                    )}
                  </div>
                </div>
                <a href='/more-news'><div className='text-white'>News</div></a>
                <a href='/contact-us'><div className='text-white'>Contact</div></a>
              </div>
            </div>
          </div> : <></>}
        <Navbar expand="lg" data-bs-theme="dark" className="text-[white]" style={navbarmd} collapseOnSelect>
          <Navbar.Brand href="/dashboard" className='font-bold transition duration-[1000ms] text-sm'>
            <img className='w-10' src={Logo} alt="Logo" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="offcanvasNavbar" style={{ marginRight: '15px' }} onClick={handleToggleSide} />
          <Navbar.Collapse id="offcanvasNavbar">
            {/* Kosongkan atau sesuaikan jika diperlukan */}
          </Navbar.Collapse>
        </Navbar>

      </>
    );
  }
}


export function NavigationBarFix() {
  const { md, lg } = useMediaQueries()
  const [show, setShow] = useState(false);
  const [showSide, setShowSide] = useState(false);
  const [isExitSide, setExistSide] = useState(false);
  const [isExiting, setIsExiting] = useState(false);

  const handleToggle = () => {
    if (show) {
      setIsExiting(true);
      setTimeout(() => {
        setShow(false);
        setIsExiting(false);
      }, 450); // Waktu harus sesuai dengan durasi animasi keluar
    } else {
      setShow(true);
    }
  };

  const handleToggleSide = () => {
    if (showSide) {
      setExistSide(true);
      setTimeout(() => {
        setShowSide(false);
        setExistSide(false);
      }, 450); // Waktu harus sesuai dengan durasi animasi keluar
    } else {
      setShowSide(true);
    }
  };

  const [navbarmd, setNavbarmdStyle] = useState({
    height: '75px', paddingLeft: '1rem', paddingRight: '1rem', backgroundColor: 'rgba(0, 0, 0, 0.7)',
  })
  const [navbarStyle, setNavbarStyle] = useState({
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    paddingLeft: '30px',
    // paddingRight: '30px',
    paddingTop: '15px',
    paddingBottom: '15px',
    transition: 'background-color 0.3s, padding 0.3s',
    width: '100%'
  });

  const handleScroll = () => {
    if (window.scrollY > 0) {
      setNavbarStyle({
        backgroundColor: 'rgba(0, 0, 0, 0.7)',
        paddingLeft: '30px',
        // paddingRight: '50px',
        paddingTop: '20px',
        paddingBottom: '20px',
        transition: 'background-color 0.3s, padding 0.3s'
      });

      setNavbarmdStyle({
        backgroundColor: 'rgba(0, 0, 0, 0.7)',
        transition: 'background-color 0.3s, padding 0.3s',
        height: '75px', paddingLeft: '1rem', paddingRight: '1rem'
      })
    } else {
      setNavbarStyle({
        backgroundColor: 'rgba(0, 0, 0, 0.7)',
        paddingLeft: '30px',
        // paddingRight: '50px',
        paddingTop: '15px',
        paddingBottom: '15px',
        transition: 'background-color 0.3s, padding 0.3s'
      });

      setNavbarmdStyle({
        backgroundColor: 'rgba(0, 0, 0, 0.7)',
        transition: 'background-color 0.3s, padding 0.3s',
        height: '75px', paddingLeft: '1rem', paddingRight: '1rem'
      })
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  if (lg) {
    return (
      <Navbar expand="sm" data-bs-theme="dark" className="text-[white]" style={navbarStyle}>
        <div className='flex justify-between w-full mx-[5vw] '>
          <Navbar.Brand href="/dashboard" className='font-bold transition duration-[1000ms] hover:scale-[2]' style={{ fontSize: '28px' }}>
            <img width={35} src={Logo} alt="Logo" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav" className="justify-content-between">
            <Nav className="ms-auto text-[white]">
              <Nav.Link style={{ paddingRight: '100px', color: 'white', fontSize: '18px' }} href="/">Bio</Nav.Link>
              <NavDropdown title="Artworks" className='customDropdown' style={{ paddingRight: '3em', fontSize: '18px' }} id="basic-nav-dropdown">
                <NavDropdown.Item href="/art/sculpture" style={{ borderBottom: '1px solid white' }}>Art Sculpture</NavDropdown.Item>
                <NavDropdown.Item href="/art/product" style={{ borderBottom: '1px solid white' }}>Art Product</NavDropdown.Item>
                <NavDropdown.Item href="/art/facade" style={{ borderBottom: '1px solid white' }}>Art Facade</NavDropdown.Item>
                <NavDropdown.Item href="/art/building" style={{ borderBottom: '1px solid white' }}>Art Building</NavDropdown.Item>
              </NavDropdown>
              <Nav.Link style={{ paddingRight: '50px', color: 'white', fontSize: '18px' }} href="/more-news">News</Nav.Link>
              <Nav.Link style={{ paddingRight: '50px', color: 'white', fontSize: '18px' }} href="/contact-us">Contact</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </div>
      </Navbar>
    );
  }

  if (md) {
    return (
      <>
        {showSide ?
          <div className='background-half'>
            <div className={`${showSide ? 'slide-in-blurred-top' : ''} ${isExitSide ? 'slide-out-blurred-top' : ''} bg-black w-[70vw] h-[100vh]`} >
              <div className='flex flex-col bg-black w-[100%] h-[100vh] mx-8 py-[10vh] text-[5vw] gap-10 text-white'>
                <div className='flex justify-end mr-10'>
                  <div className='text-[3vw] relative right-0' onClick={handleToggleSide}>X</div>
                </div>
                <div className='flex justify-between items-center'>
                  <a href='/dashboard'><div className='text-white'>Bio</div></a>
                </div>
                <div className=''>
                  <div onClick={handleToggle} className='cursor-pointer mb-2 bg-black'>
                    Art Works
                  </div>
                  <div className={`${show ? 'slide-in-blurred-top' : ''} ${isExiting ? 'slide-out-blurred-top' : ''} overflow-hidden`}>
                    {show && (
                      <div className='flex flex-col gap-4 mt-2'>
                        <a href="/art/sculpture">
                          <div className='text-[4vw] border-b border-white w-[55%] text-white pb-2'>Art Sculpture</div>
                        </a>
                        <a href="/art/product">
                          <div className='text-[4vw] border-b border-white w-[55%] text-white pb-2'>Art Product</div>
                        </a>
                        <a href="/art/facade">
                          <div className='text-[4vw] border-b border-white w-[55%] text-white pb-2'>Art Facade</div>
                        </a>
                        <a href="/art/building">
                          <div className='text-[4vw] border-b border-white w-[55%] text-white pb-2'>Art Building</div>
                        </a>
                      </div>
                    )}
                  </div>
                </div>
                <a href='/more-news'><div className='text-white'>News</div></a>
                <a href='/contact-us'><div className='text-white'>Contact</div></a>
              </div>
            </div>
          </div> : <></>}
        <Navbar expand="lg" data-bs-theme="dark" className="text-[white]" style={navbarmd} collapseOnSelect>
          <Navbar.Brand href="/dashboard" className='font-bold transition duration-[1000ms] text-sm'>
            <img className='w-10' src={Logo} alt="Logo" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="offcanvasNavbar" style={{ marginRight: '15px' }} onClick={handleToggleSide} />
          <Navbar.Collapse id="offcanvasNavbar">
            {/* Kosongkan atau sesuaikan jika diperlukan */}
          </Navbar.Collapse>
        </Navbar>

      </>
    );
  }
}


export default NavigationBar;
