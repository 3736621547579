import React, { useEffect, useState } from 'react';
import { apiHandler } from '../api/APIHandler';
import { useMediaQueries } from '../hook';

const processIframe = (iframeString) => {
  const withoutStyle = iframeString.replace(/\s*style="[^"]*"/, '');
  const withUpdatedWidth = withoutStyle.replace(/width="[^"]*"/, 'width="100%"');
  const withUpdatedHeight = withUpdatedWidth.replace(/height="[^"]*"/, 'height="300"');

  return withUpdatedHeight;
};

const processIframeLg = (iframeString) => {
  const withoutStyle = iframeString.replace(/\s*style="[^"]*"/, '');
  const withUpdatedWidth = withoutStyle.replace(/width="[^"]*"/, 'width="100%"');
  const withUpdatedHeight = withUpdatedWidth.replace(/height="[^"]*"/, 'height="100%"');

  return withUpdatedHeight;
};


const GoogleMapBali = ({ map }) => {
  return (
    <div className='w-[35vw] h-[35vw]'
      dangerouslySetInnerHTML={{ __html: processIframeLg(map) }}
    />
  );
};


const GoogleMapJakarta = ({ map }) => {
  return (
    <div className='w-[35vw] h-[35vw]'
      dangerouslySetInnerHTML={{ __html: processIframeLg(map) }}
    />
  );
};


const GoogleMapJakartamd = ({ map }) => {
  return (
    <div
      dangerouslySetInnerHTML={{ __html: processIframe(map) }}
    />
  );
};

const GoogleMapBalimd = ({ map }) => {
  return (
    <div
      dangerouslySetInnerHTML={{ __html: processIframe(map) }}
    />
  );
};

function MapSection() {
  const [data, setData] = useState({});
  const { md, lg } = useMediaQueries()

  useEffect(() => {
    // Fetch photo URL from an API
    const fetchPhoto = async () => {
      try {
        const fetchedData = await apiHandler('items/contact_address');
        console.log(fetchedData)
        setData(fetchedData);
      } catch (error) {
        console.error('Error fetching photo URL:', error);
      }
    };

    fetchPhoto();
  }, []);

  if (lg) {
    return (
      <div className='pl-[11vw] pr-[8vw] mt-10 mb-10 flex flex-col justify-center w-full h-full'>
        <div className='relative flex items-center mb-10 text-[24px]'>
          <div className='relative z-10'>
            View on map
          </div>
          <svg className='w-10 ml-2 z-10' xmlns="http://www.w3.org/2000/svg" data-name="Layer 1" viewBox="0 0 24 24" id="arrow-right">
            <path fill="#1B1A1A" d="M17.92,11.62a1,1,0,0,0-.21-.33l-5-5a1,1,0,0,0-1.42,1.42L14.59,11H7a1,1,0,0,0,0,2h7.59l-3.3,3.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0l5-5a1,1,0,0,0,.21-.33A1,1,0,0,0,17.92,11.62Z"></path>
          </svg>
          <div className='absolute inset-x-0 w-[180px] bottom-0 h-[2px] bg-current' />
        </div>

        <div className='flex justify-between ' style={{ width: '100%' }}>
          <div style={{ width: '100%' }}>
            <div className='font-bold text-[24px] mb-2'>JAKARTA</div>
            <div className='text-[#9CA3AF] text-[18px] mb-2' style={{ width: '100%', overflowWrap: 'break-word' }}>
              {data.address_one ? data.address_one : "Loading"}
            </div>
            {
              data.embed_map_one ? 
                <GoogleMapJakarta map={data.embed_map_one} /> : <></>
            }
          </div>

          <div style={{ width: '100%' }}>
            <div className='font-bold text-[24px] mb-2'>BALI</div>
            <div className='text-[#9CA3AF] text-[18px] mb-2' style={{ width: '100%', overflowWrap: 'break-word' }}>
              {data.address_two ? data.address_two : "Loading"}
            </div>
            {
              data.embed_map_two ?
                <GoogleMapBali map={data.embed_map_two} /> : <></>
            }
          </div>
        </div>
      </div>
    );
  }

  if (md) {
    return (
      <div className='mx-2 my-10'>
        <div className='relative flex items-center mb-10 text-[24px]'>
          <div className='relative z-10 text-lg'>
            View on map
          </div>
          <svg className='w-10 ml-2 z-10' xmlns="http://www.w3.org/2000/svg" data-name="Layer 1" viewBox="0 0 24 24" id="arrow-right">
            <path fill="#1B1A1A" d="M17.92,11.62a1,1,0,0,0-.21-.33l-5-5a1,1,0,0,0-1.42,1.42L14.59,11H7a1,1,0,0,0,0,2h7.59l-3.3,3.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0l5-5a1,1,0,0,0,.21-.33A1,1,0,0,0,17.92,11.62Z"></path>
          </svg>
          <div className='absolute inset-x-0 w-[180px] bottom-0 h-[2px] bg-current' />
        </div>

        <div className='flex justify-center flex-col items-center gap-4 ' style={{ width: '100%' }}>
          <div className='w-full h-full'>
            <div className='font-bold text-xl mb-2 '>JAKARTA</div>
            <div className='text-[#9CA3AF] text-sm mb-2' style={{ overflowWrap: 'break-word' }}>
              {data.address_one ? data.address_one : "Loading"}
            </div>
            <GoogleMapJakartamd map={data.embed_map_one ? data.embed_map_one : "Loading"} />
          </div>

          <div className=' w-full h-full'>
            <div className='font-bold text-xl mb-2'>BALI</div>
            <div className='text-[#9CA3AF] text-sm mb-2' style={{ overflowWrap: 'break-word' }}>
              {data.address_two ? data.address_two : "Loading"}
            </div>
            <GoogleMapBalimd map={data.embed_map_two ? data.embed_map_two : "Loading"} />
          </div>
        </div>
      </div>
    );
  }
}

export default MapSection;
